<template>
  <div class="support-box">
    <div class="intro flex">
      <div class="imgs">
        <el-image :src="chuneng3"></el-image>
      </div>
      <div class="detail flex">
        <span class="title">储能式充电系统</span>
        <span class="subtitle">PowerMars SC160kW-80/160kWh</span>
      </div>
    </div>
    <div class="support flex">
      <div class="left flex">
        {{ tab }}
        <div
          class="item flex"
          :class="[tab == 1 ? 'active' : '']"
          @click="handleTab(1)"
        >
          常见问题
        </div>
        <div
          class="item flex"
          :class="[tab == 2 ? 'active' : '']"
          @click="handleTab(2)"
        >
          安装视频
        </div>
        <div
          class="item flex"
          :class="[tab == 3 ? 'active' : '']"
          @click="handleTab(3)"
        >
          用户手册
        </div>
      </div>
      <div class="right">
        <template v-if="tab == 1">
          <div class="collapse">
            <el-collapse v-model="activeName" accordion>
              <el-collapse-item title="什么是储能式充电系统？" name="1">
                <div>
                  储能式充电系统是一种将储能柜与充电桩相结合的系统，通过储能的方式摆脱变压器容量的约束，从而达到低输入高输出。这种系统可以帮助平衡能源供需，提供短时高效的输出和长时的能量储备。
                </div>
              </el-collapse-item>
              <el-collapse-item
                title="如果我购买了SC160kW-80kWh，支持升级成160kWh吗？"
                name="2"
              >
                <div>支持最高升级至160kWh</div>
              </el-collapse-item>
              <el-collapse-item title="充电功能是否适配我的车型？" name="3">
                <div>目前本公司充电桩适配市面上99.99%的新能源车型。</div>
              </el-collapse-item>
              <el-collapse-item
                title="如果我想安装在自己家里的车库内可以吗？"
                name="4"
              >
                <div>
                  可以，具体安装条件需要根据现场情况进行适配，本公司也提供更小，且对供电需求更低的充电设备。
                </div>
              </el-collapse-item>
              <el-collapse-item title="如何给储能充电？" name="5">
                <div>通过直连电网或者连接光伏组件都可以为储能充电。</div>
              </el-collapse-item>
              <el-collapse-item title="工作时候的噪音大不大？" name="6">
                <div>
                  该产品充电时噪音可控制在75dB以内，如果需要更静音的款式可以了解一下PowerBuru系列家用充电桩。
                </div>
              </el-collapse-item>
              <el-collapse-item
                title="PowerMars储能式充电系统的循环周期"
                name="7"
              >
                <div>最快可达到一小时充满，半小时放空的循环效率。</div>
              </el-collapse-item>
              <el-collapse-item
                title="PowerMars储能式充电系统的电池是什么类型？"
                name="8"
              >
                <div>先进的磷酸铁锂电芯将持续为您带来便利的充储服务</div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        activeName: "0",
        chuneng3: require("@/assets/product/img/chuneng3.png"),
        tab: 1,
      };
    },
    methods: {
      handleTab(t) {
        this.tab = t;
      },
    },
  };
</script>

<style lang="less">
  .support-box {
    width: 120rem;
    margin: 0 auto;
  }
  .intro {
    justify-content: center;
    margin-top: 13rem;

    .imgs {
      width: 42rem;
      margin-right: 10rem;
    }

    .detail {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 6rem;

      .title {
        font-size: 4.8rem;
        color: #f5f5f3;
        font-family: HarmonyBold;
        margin-bottom: 1.2rem;
      }

      .subtitle {
        border-radius: 1.5rem;
        background: #1e1e1e;
        color: #ffffff;
        font-size: 2rem;
        padding: 1.1rem 1.9rem;
      }
    }
  }
  .support {
    padding: 12.9rem 0;

    .left {
      width: 29.5rem;
      flex-direction: column;
      height: fit-content;
      border-right: 1px solid #595757;
      margin-top: 2.2rem;

      .item {
        width: 33rem;
        justify-content: space-between;
        color: #9fa0a0;
        font-size: 3rem;
        font-family: HarmonyBold;
        margin-bottom: 7.5rem;
        cursor: pointer;

        &.active {
          color: #ffffff;
        }
      }
    }

    .right {
      flex: 1;
      margin-left: 17.5rem;

      .title {
        text-align: left;
        color: #ffffff;
        font-size: 3.8rem;
        font-weight: bold;
        margin-bottom: 3.7rem;
      }

      .collapse {
        .el-collapse {
          border: 0;
        }
        .el-collapse-item__header,
        .el-collapse-item__wrap {
          color: #ffffff;
          font-size: 2.4rem;
          font-family: HarmonyRegular;
          background-color: inherit;
          border-bottom: 0;

          .el-collapse-item__content {
            font-family: AlibabaPuHuiTi35;
            font-size: 2rem;
            text-align: left;
            color: inherit;
          }
        }
        .el-collapse-item__header {
          padding: 1.7rem 0;
        }
      }
    }
  }
</style>
